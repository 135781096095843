.App {
  display: flex;
  flex-direction: column;
  background-color: coral;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 100vh;
  /* padding-top: 20px; */
}

.colors {
  color: #fa198b;
  color: #574ae2;
  color: #b91372;
  color: #31081f;
  color: #6b0f1a;
  color: #0e0004;
  color: #edffec;
  color: #9ced98;
  color: #c6fcc3;
  color: #8fd694;
  color: #b3b9b3;
  color: #ffe99b;
}

.navbaropen {
  position: absolute;
  width: 100%;
  background-color: #fa198b;
  height: max-content;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

.logonavclosed {
  height: 110px;
  width: 110px;
  position: absolute;
  right: 12px;
}

.logonavopen {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
}

.logofooter {
  height: 110px;
  width: 110px;
}

.navbarclosed {
  background-color: #fa198b;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarclosedmobile {
  background-color: #fa198b;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  padding-left: 12px;
  align-items: center;
}

.navtitle {
  color: #fff;
  font-size: xx-large;
  font-weight: 700;
}

.navtitlemobile {
  color: #fff;
  margin-top: 20px;
  font-size: xx-large;
  font-weight: 700;
}

.languages {
  /* position: absolute; */
  /* right: 8px; */
  display: flex;
  flex-direction: column;
  background-color: #574ae2;
}

.languages_mobile {
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: column;
  background-color: #574ae2;
}

.language:hover {
  color: #fa198b;
}

.language {
  background-color: #574ae2;
  color: white;
  padding-bottom: 10px;
  border-width: 0px;
}

.image {
  max-width: 100%;
  max-height: 700px;
  /* object-fit: scale-down; */
  height: auto;
  width: auto;
  border-radius: 7px;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #c6fcc3; */
  margin-bottom: 45px;
  border-bottom-style: solid;
  border-top-style: solid;
  padding-top: 25px;
  padding-bottom: 35px;
  border-bottom-color: #574ae2;
  border-top-color: #574ae2;
  border-bottom-width: 2px;
  border-top-width: 2px;
}

.icon {
  background-color: #fa198b;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border-width: 0px;
  position: absolute;
  left: 12px;
  top: 12px;
}

.footer_icons {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  align-self: flex-end;
}

.footer_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.footer_icons_mobile {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 20px;
}

.footer_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #574ae2;
  height: 32px;
  width: 32px;
  border-radius: 24px;
  border-style: none;
  padding: 15px;
  margin-right: 3px;
}

.footer_icon_email {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #574ae2;
  height: 32px;
  width: 32px;
  border-radius: 24px;
  border-style: none;
  padding: 15px;
  margin-right: 3px;
  margin-top: 2px;
}

.footer_icon_email:hover {
  cursor: pointer;
  background-color: #fa198b;
}

.footer_icon:hover {
  cursor: pointer;
  background-color: #fa198b;
}

.options {
  background-color: #fa198b;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.branding {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main {
  background-color: #6290ec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 10vh;
}

.option {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: larger;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #574ae2;
}

.option:hover {
  color: #574ae2;
  border-radius: 5px;
  /* padding-right: 5px; */
  /* padding-left: 5px; */
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #574ae2;
  height: auto;
  padding: 12px;
}

.footer_mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #574ae2;
  height: auto;
  padding: 12px;
  padding-top: 25px;
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  height: auto;
  width: 100%;
  padding-bottom: 80px;
}

.background_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* height: auto; */
  width: 100%;
  /* padding-bottom: 80px; */
}

.pagetitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 700;
  background-color: #574ae2;
  color: #c6fcc3;
  /* color: #fff; */
  /* border-bottom-color: #fa198b;
  border-bottom-style: solid;
  border-top-color: #fa198b;
  border-top-style: solid;
  border-width: 2px; */
  height: 65px;
  align-self: stretch;
  margin-bottom: 55px;
}

.projects_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: medium;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 15px;
  height: auto;
  width: 80%;
  margin-bottom: 55px;
  text-align: left;
  color: #a08b8b;
  font-weight: 500;
}

.projects_box_desk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: medium;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 15px;
  height: auto;
  width: 60%;
  margin-bottom: 55px;
  text-align: left;
  color: #a08b8b;
  font-weight: 500;
}

.projects_one {
  color: #574ae2;
  font-weight: 600;
}

.projectsintro {
  color: #282c34;
}

.projects_question {
  color: #282c34;
}

.explanation {
  display: flex;
  font-size: medium;
  margin-top: 20px;
  padding: 15px;
  height: auto;
  width: 80%;
  text-align: left;
  color: #a08b8b;
}

.contact_link {
  text-decoration: none;
  margin-left: 5px;
  color: #574ae2;
}

.contact_link:hover {
  color: #61dafb;
}

.pagesubtitle {
  font-size: x-large;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  /* color: #4d0b31; */
  color: #282c34;
  text-align: center;
  /* border-color: #fa198b; */
  /* border-style: ridge; */
  /* border-width: 1px; */
  /* padding: 5px; */
}

.description {
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #574ae2;
  text-align: center;
}

.divi {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  min-height: max-content;
  width: 70%;
}

.divi_projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-left: 50px;
  padding-right: 50px;
  min-height: max-content;
  width: 70%;
  /* border-bottom-style: solid;
  border-top-style: solid;
  padding-top: 35px;
  padding-bottom: 35px;
  border-bottom-color: #574ae2;
  border-top-color: #574ae2;
  border-bottom-width: 2px;
  border-top-width: 2px; */
}

.right_button {
  height: 25px;
  width: 25px;
  border-width: 0;
  border-radius: 32px;
  position: absolute;
  right: 15px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_button {
  height: 25px;
  width: 25px;
  border-width: 0;
  border-radius: 32px;
  position: absolute;
  left: 15px;
  /* margin-top: 25px; */
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled_button {
  height: 25px;
  width: 25px;
  opacity: 50%;
  border-width: 0;
  border-radius: 32px;
  position: absolute;
  left: 15px;
  /* margin-top: 25px; */
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.art_left_button {
  height: 25px;
  width: 25px;
  border-width: 0;
  border-radius: 32px;
  position: absolute;
  left: 15px;
  margin-top: 25px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.art_disabled_button {
  height: 25px;
  width: 25px;
  opacity: 50%;
  border-width: 0;
  border-radius: 32px;
  position: absolute;
  left: 15px;
  margin-top: 25px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cuadro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 340px;
  width: 340px;
  max-width: 90%;
  border-color: #fa198b;
  border-style: solid;
  border-width: 0.5vw;
  background-color: white;
  margin-top: 50px;
  text-decoration: none;
}

.contactoption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  color: black;
  text-decoration: none;
}

.contacttext {
  margin-left: 20px;
  font-weight: 500;
  text-decoration: none;
}

.picture {
  max-width: 100%;
  max-height: 700px;
  object-fit: scale-down;
  height: auto;
  width: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
}
.textboxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  margin-bottom: 50px;
}

.textbox {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: auto;
}

.title {
  color: #574ae2;
}

.example {
  max-width: 100%;
  max-height: 700px;
  height: auto;
  width: auto;
  border-radius: 7px;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.8);
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  opacity: 0;
  font-size: 20px;
  position: absolute;
  color: #131617;
  background-color: rgba(255, 239, 250, 0.831);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 400ms ease-out;
  /* -webkit-transition: all 400ms ease-out; */
  /* -moz-transition: all 400ms ease-out; */
  /* -o-transition: all 400ms ease-out; */
  /* -ms-transition: all 400ms ease-out; */
  /* text-align: center; */
}

.example .content:hover {
  opacity: 1;
}

.example .content .text {
  height: auto;
  width: auto;
  font-size: medium;
  font-weight: 600;
  opacity: 1;
  transition-delay: 0s;
  padding: 10px;
}

.example .content .arttext {
  /* height: auto; */
  /* width: auto; */
  align-self: center;
  /* background-color: #574ae2; */
  font-size: medium;
  font-weight: 600;
  opacity: 1;
  transition-delay: 0s;
  padding: 10px;
}

.callme {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  margin-top: 8px;
}

.copyright {
  position: relative;
  align-self: flex-end;
  color: #fff;
  font-size: small;
  padding-top: 5px;
  text-align: center;
  /* align-self: center; */
}

.copyright_mobile {
  margin-top: 20px;
  color: #fff;
  font-size: small;
  border-top-style: solid;
  padding-top: 5px;
  text-align: center;
  /* align-self: center; */
}

.home {
  display: flex;
  flex: 4;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.squares {
  display: flex;
}

.square_a {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 60vh;
  background-color: #31081f;
  color: #fff;
  text-decoration: none;
  font-size: xx-large;
  font-weight: 600;
}

.square_a:hover {
  background-color: #6290ec;
  opacity: 75%;
}

.square_b:hover {
  background-color: #61dafb;
}

.square_b {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 60vh;
  background-color: #574ae2;
  color: #c6fcc3;
  text-decoration: none;
  font-size: xx-large;
  font-weight: 600;
}

.square_a_mobile {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40vh;
  background-color: #31081f;
  color: #fff;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
}

.square_b_mobile {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40vh;
  background-color: #574ae2;
  color: #fff;
  text-decoration: none;
  font-size: larger;
  font-weight: 600;
}

.hometext {
  margin: 40px;
  width: 70%;
  font-size: medium;
  align-self: center;
  text-align: justify;
}

.strategypage {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  color: #282c34;
}

.strategyintro {
  align-self: center;
  margin: 20px;
  /* border-bottom-style: solid; */
}

.strategymain {
  border-top-style: solid;
  border-color: #574ae2;
  border-width: 2px;
}

.strategyleft {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.strategyright {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.strategyimg {
  width: 100%;
  min-width: 50%;
  height: auto;
}

.strategyimgmobile {
  width: 250px;
  object-fit: cover;
  height: 250px;
}

.stext {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #574ae2;
}

.stextmobile {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #574ae2;
  /* font-size: small; */
  font-size: 12px;
}

.strategytext {
  --b: 2px; /* thickness of the border */
  --c: #fff; /* color of the border */
  --w: 20px; /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
  /* border-style: solid; */
  /* border-color: #fff; */
  width: 60%;
  margin: 10px;
  padding: 15px;
}

.arttext {
  --b: 2px; /* thickness of the border */
  --c: #574ae2; /* color of the border */
  --w: 15px; /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(
        from 90deg at top var(--b) left var(--b),
        var(--_g)
      )
      0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
      100% 100% / var(--_p);
  /* border-style: solid; */
  /* border-color: #fff; */
  /* width: auto; */
  margin: 20px;
  padding: 150px;
}

.here {
  text-decoration: none;
  color: #574ae2;
}

.here:hover {
  color: #61dafb;
}

.fullsize {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 3;
}

.iconx {
  position: absolute;
  top: 162px;
  left: 12px;
  background-color: black;
  border-width: 0px;
}

.examplefull {
  max-width: 100%;
  height: auto;
  width: auto;
  position: relative;
  padding: 2px;
  display: flex;
  flex-direction: column;
  color: white;
  overflow: hidden;
}

.textfull {
  color: white;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}

.picturefull {
  max-width: 100%;
  max-height: 700px;
  object-fit: scale-down;
  height: auto;
  width: auto;
}

.allprojects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-style: solid;
  border-color: #574ae2;
  border-width: 0.5vw;
  background-color: #574ae2;
}

.photo {
  height: 450px;
  width: 31.6vw;
  border-style: solid;
  border-width: 0.4vw;
  border-color: #574ae2;
  object-fit: cover;
}

.photo_mobile {
  height: 120px;
  width: 32vw;
  border-width: 0.5vw;
  border-style: solid;
  border-color: #574ae2;
  object-fit: cover;
}

/*////////////////////////*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
